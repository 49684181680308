import request from "./request"

//发送短信验证码
export const sendSms = (data) => {
    return request("post", "/api/sendCode", data);
}

//校验验证码
export function checkCode(phone, code, type) {
    return request("get", "/log/logSms/checkCode", {
        smsCode: code, mobile: phone, type: type
    });
}
//获取当前用户信息
export const userInfo = () => {
    return request("get", "/api/member/member/detail");
}

//微信登录二维码
export const wxLoginCode = (params) => {
    return request("get", "/wxLoginCode", params);
}
// 退出登录
export const loginOut = () => {
    return request("post", "/api/member/memberLoginOut");
}
// 获取新的流水号 /其他前端登录用的
export const getNewSerialNum = () => {
    return request("post", "/api/member/getNewSerialNum");
}
