<!--  -->
<template>
  <!--  @open="handleOpen"
  @close="handleClose"-->
  <div class="menu">
    <el-menu
      ref="menu"
      :default-active="currentRouter"
      router
      class="el-menu-vertical-demo"
      background-color="#1c2b36"
      text-color="#fff"
      active-text-color="#fff"
      :collapse="JSON.parse($store.state.collapse)"
      :unique-opened="true"
      :style="{ width: $store.state.asideWidth + 'px' }"
    >
      <template v-for="item in menu">
        <el-submenu :index="item.id" :key="item.id" v-if="item.children.length > 0">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
          <template v-for="items in item.children">
            <el-menu-item :index="items.path" :key="items.id">
              <i :class="items.icon"></i>
              <span slot="title">{{ items.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item :index="item.path" :key="item.id" v-else>
          <i :class="item.icon"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "bAside",
  data () {
    return {
      currentRouter: "/dashboard",
      menu: [
        {
          id: "000",
          path: "/dashboard",
          title: "控制台",
          icon: "iconfont pdxkongzhitai",
          children: "",
        },
        {
          id: "001",
          path: "/user",
          title: "用户中心",
          icon: "iconfont pdxyonghuxinxi1",
          children: [
            {
              id: "1-001",
              path: "/user/info",
              title: "账号信息",
              icon: "iconfont pdxyonghuxinxi",
              children: [],
            },
            {
              id: "1-002",
              path: "/user/set",
              title: "安全设置",
              icon: "iconfont pdxanquanshezhi",
              children: [],
            },
          ],
        },
         {
          id: "002",
          path: "/message",
          title: "消息中心",
          icon: "iconfont pdxxiaoxi ",
          children: "",
        },
        {
          id: "003",
          path: "/order",
          title: "订单信息",
          icon: "iconfont pdxdingdanxinxi",
          children: "",
        },
        {
          id: "004",
          path: "/api",
          title: "接口管理",
          icon: "iconfont pdxapi",
          children: "",
        },
        {
          id: "005",
          path: "/secret",
          title: "秘钥管理",
          icon: "iconfont pdxmiyue",
          children: "",
        },
      ],
    };
  },
  beforeMount () {
    this.currentRouter = this.$route.path;
  },
  mounted () {
    window.addEventListener("resize", () => {
      let pageWidth = window.innerWidth;
      if (pageWidth > 1200) {
        this.$store.commit("setCollapse", false);
        this.$store.commit("setAsideWidth", 180);
      } else {
        this.$store.commit("setCollapse", true);
        this.$store.commit("setAsideWidth", 64);
      }
    });
  },
  watch: {
    $route: function (to) {
      this.currentRouter = to.path;
    },
  },

  components: {},

  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
.menu {
  height: 100%;

  .el-menu {
    // width: 100%;
    height: calc(100vh - 66px);
    position: fixed;
    left: 0;
    top: 66px;
    i {
      color: #fff;
    }
    .el-menu-item:focus,
    .el-menu-item:hover {
      // background: #409EFF !important;
    }
    .el-submenu__title:hover {
      background-color: #409eff !important;
    }
    .el-submenu .el-menu-item {
      min-width: 170px;
    }
    .is-active {
      background: #409eff !important;
    }
    .el-submenu__title i,
    .el-menu-item i {
      margin-right: 20px !important;
    }
    .el-menu--inline .el-menu-item-group div {
      padding-left: 60px !important;
    }
  }
}
</style>