<template>
  <div>
    <el-container>
      <el-header>
        <bHeader></bHeader>
      </el-header>
      <el-container>
        <el-aside :style="{'width':$store.state.asideWidth+'px'}">
          <bAside></bAside>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import bHeader from "@/components/layout/header"
import bAside from "@/components/layout/aside"
export default {
  data () {
    return {
    };
  },

  components: {
    bHeader,
    bAside
  },
  mounted () {
    let arry = []
    let w = window.location.hash
    let start = w.indexOf("?")
    let end = w.length
    w = w.substring(start + 1, end)
    w = w.split('&')
    w.forEach(item => {
      item.split("=").forEach((item, index) => {
        if (index == 1) {
          arry.push(item)
        }

      })
    });
    console.log(arry);
    localStorage.setItem("source", JSON.stringify(arry))
  },
  computed: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.el-container {
  height: 100vh;
}
.el-header {
  padding: 0;
  height: 66px !important;
}
.el-aside {
  height: 100%;
  overflow: hidden;
}
.el-main {
  padding: 10px 20px !important;
  height: calc(100vh - 66px);
  background: rgba(203, 203, 210, 0.15);
  // box-sizing: content-box;
  // background: $bg;
}
</style>