<!--  -->
<template>
  <div class="header" :style="{ background: bg }">
    <el-row :gutter="10">
      <el-col :span="14" :xs="12">
        <el-row>
          <el-col>
            <div class="logo">
              <div class="img">
                <img src="@/assets/img/logo.png" alt />
              </div>
              <div class="switch" @click="change">
                <i class="iconfont pdxsanhang"></i>
              </div>
              <div class="search hidden-xs-only">
                <i class="iconfont pdxsousuo"></i>
                <el-input v-model="input" placeholder="搜索......"></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="10" :xs="11">
        <!-- <div class="email">
          <i class="iconfont pdxEnvelopeMailLetterSendEmail"></i>
        </div>-->
         <el-dropdown trigger="click" @command="jump" type=''>
          <div class="avator">
             <i class="iconfont pdxlingdang"></i>
          </div>
            <el-dropdown-menu slot="dropdown">
               <div class="message">
              <el-dropdown-item v-for="(item,index) in 15" :key="index" :command='index'>
                  <div class="message-line">
                    <div class="left">
                      <img src="@/assets/img/message-avatar.jpg" alt />
                    </div>
                    <div class="right">
                      <p>自营接口申请审核结果(来自远方的信)</p>
                      <span></span>
                      <!-- <p>2021-08-07 13:49:47</p> -->
                    </div>
                  </div>
              </el-dropdown-item>
              </div>
            <p class="clear-all-txt"><a href="">清空全部</a><a href="">全部已读</a></p>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="avator">
            <img :src="avatar" alt />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="iconfont pdxtuichu" command="logout">退出</el-dropdown-item>
            <el-dropdown-item icon="iconfont  pdxwinfo-icon-gerenxinxi" command="page">个人信息</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { userInfo,loginOut } from "@/api/index.js";
export default {
  data () {
    return {
      input: "",
      bg: "#1c2b36",
      display: false,
      avatar: require("@/assets/img/avator.png"),
    };
  },
  created () {
    userInfo().then((resp) => {
      this.$store.commit("setUserInfo", resp.data);
      let userInfo = JSON.parse(this.$store.state.userInfo);
      if (userInfo.avatar) {
        this.avatar = userInfo.avatar;
      }
      this.username = userInfo.nickname || userInfo.loginName;
    });
  },
  mounted () {
    window.addEventListener("scroll", () => {
      let scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scroll > 66) {
        this.bg = "rgba(0, 0, 0, .85)";
      } else {
        this.bg = "#1c2b36";
      }
    });
  },
  components: {},

  computed: {},

  methods: {
    jump(i){
      this.$router.push({name:'message'})
    
    },
    handleCommand (command) {
      if ("logout" == command) {
        this.$confirm("确定退出系统?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.setItem("token", "");
            localStorage.setItem("userInfo", "");
            localStorage.setItem("fortressRefreshToken", "");
            localStorage.setItem("fortressExpiredTime", "");
            loginOut().then(res => {
              console.log(res)
            })
            this.$router.replace({ name: "login" });
          })
          .catch(() => { });
      } else {
        this.$router.push('/user/info')
      }

    },

    tabMsg () {
      if (this.display) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    back () {
      // let path = JSON.parse(localStorage.getItem("source"));
      // if (path && path.length > 0) {
      //   window.location = "http://" + path[0] + ":" + path[1];
      // }
    },
    change () {
      let type = false;
      let wid = 0;
      if (this.$store.state.collapse) {
        type = false;
        wid = 180;
      } else {
        type = true;
        wid = 64;
      }
      this.$nextTick(() => {
        this.$store.commit("setCollapse", type);
        this.$store.commit("setAsideWidth", wid);
      });
    },
  },
};
</script>
<style>
/* 修改滚动条的样式 */

.message::-webkit-scrollbar {
    width: 5px;
}

.message::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d9d9d9;
}

.message::-webkit-scrollbar-track {
    border-radius: 0;
    background:#fff ;
}
</style>
<style lang='scss' scoped>
.el-dropdown-menu {
  margin-top: 0 !important;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(15px);
}
.message{
  height: 350px;
  overflow: auto;
    .message-line{
      padding: 10px 0 ;
      width: 240px;
      display: flex;
      align-items: center;
      // margin-bottom: 5px;
      .left {
        line-height: 1;
        img {
          margin-right: 10px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      .right {
        flex:1;
        position: relative;
        p {
          line-height: 1;
          color: #7d7d7d;
          font-size: 14px;
          @include show_line(1);
          &:last-child{
            font-size: 13px;
            line-height: 2;
          }
        }
        span{
          position: absolute;
          right: 0;
          top: -5px;;
          width: 6px;height: 6px;
          background: #e42222;
          border-radius: 50%  ;
        }
      }
    }
    }
     .clear-all-txt{
       margin-top: 15px;
        text-align:center;
        display: flex;
        align-items: center;
        padding: 0 10px;
       a{
         margin: 0 10px;
         border-radius: 40px;
         flex:1;
          padding:6px 0 ;
          color: #209e91;
          font-size: 12px;
          letter-spacing: 1px;
          border: 1px solid #209e91;
          &:first-child{
            color: #fff;
            background: #209e91;
          }
        
       }
     }         
.header {
  width: 100%;
  padding: 0 50px 0 20px;
  height: 66px;
  line-height: 66px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;
  box-shadow: 2px 0 3px rgb(0 0 0 / 50%);
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  & ::v-deep .el-row {
    height: 66px;
    line-height: 66px;
    // overflow: hidden;
    .el-col-14 {
      .logo {
        height: 100%;
        display: flex;
        align-items: center;
        .img {
          display: flex;
          align-items: center;
          height: 66px;
          img {
            width: 85%;
          }
        }
        .switch {
          margin-left: 14px;
          cursor: pointer;
        }
        .search {
          margin-left: 25px;
          display: flex;
          .el-input__inner {
            background: transparent;
            border: none;
          }
        }
      }
    }
    .el-col-10 {
      display: flex;
      justify-content: flex-end;
      .message,
      .email {
        cursor: pointer;

        i {
          display: block;
          padding: 0 10px;
          font-size: 20px;
          &:hover {
            background: #1e2632;
            opacity: 0.8;
          }
        }
      }
      .avator {
        height: 100%;
        margin: 0 18px 0 10px;
        display: flex;
        align-items: center;
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          overflow: hidden;
        }
        i{
          font-size: 20px;
          color: #fff;
          font-weight: normal;
          
        }
       
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 0 20px;
    .el-row {
      .el-col-10 {
        .avator {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
